import React, { useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const CabinetPage = () => {
  useEffect(() => {
    window.location.href = "/cabinet/sign-in"
  }, [])

  return (
    <Layout>
      <SEO title="Cabinet" />
    </Layout>
  )
}

export default CabinetPage
